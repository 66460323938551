@mixin simpleTransition( $duration: .3s ) {
	transition: all $duration;
	backface-visibility: hidden;
}

@mixin purpleHover {
	& {
		background-color: $purpleLig;
		@include simpleTransition;
	}

	&:hover {
		background-color: $purpleViv;
	}
}

@mixin pageSidePadding {
	padding-left: $sidePaddingMobile;
	padding-right: $sidePaddingMobile;
		
	@media( min-width: $tabletWidthMin ) {
		padding-left: $sidePaddingTablet;
		padding-right: $sidePaddingTablet;		
	}
	
	@media( min-width: $desktopWidthMin ) {
		padding-left: $sidePaddingDesktop;
		padding-right: $sidePaddingDesktop;		
	}	
}


@mixin simpleLoading {
	&:before,
	&:after {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		display: block;
		border: remCalc( 5 ) solid transparent;
		border-radius: 50%;
	}

	&:before {
		width: remCalc( 75 );
		height: remCalc( 75 );
		border-top-color: $purpleViv;
		border-left-color: $purpleViv;
		animation: loadSpin 1s linear infinite;
	}

	&:after {
		width: remCalc( 60 );
		height: remCalc( 60 );
		border-top-color: $purpleLig;
		border-left-color: $purpleLig;
		animation: loadSpin .8s linear infinite;
	}
}