button {
	-webkit-appearance: none;
	border: none;
	background: none;
	cursor: pointer;
}

img {

	&.lazy {
		opacity: 0;
		visibility: hidden;
		@include simpleTransition;

		&.loaded {
			opacity: 1;
			visibility: visible;
		}
	}
}

