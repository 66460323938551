.blocks {
	display: grid;
	grid-gap: remCalc( 10 );
	grid-auto-rows: remCalc( 300 );

	@include pageSidePadding;
	
	@media( min-width: $tabletWidthMin ) {
		grid-template-columns: 	repeat( auto-fill, minmax( $blockWidth, 1fr ) );
	}

}

.block {
	$block: &;
	
	position: relative;
	display: flex;
	
	align-items: flex-end;
	flex: 0 0 100%;

	overflow: hidden;
	z-index: 1;


	&.block--watched {
		cursor: pointer;
	}

	&:before,
	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
	}

	&:before{
		background: rgb(0,0,0);
		background: linear-gradient(0deg, rgba(0,0,0,0.75) 0%, rgba(0,0,0,0.75) 25%, rgba(0,0,0,0.5) 60%, rgba(0,0,0,0) 100%); 
		@include simpleTransition;
	}

	&:after {
		opacity: 0;
		visibility: hidden;
		background: rgba(0,0,0,.75);
		@include simpleTransition;
	}

	&.is-loading {
		@include simpleLoading;

		&:before {
			background: none;
		}

		&:after {
			opacity: 1;
			visibility: visible;
			background: none;
		}

		#{$block}__btns,
		#{$block}__text,
		#{$block}__img {
			opacity: .15;
		}
	}
	
	&:hover:before {
		opacity: 0;
		visibility: hidden;
	}

	&:hover:after {
		opacity: 1;
		visibility: visible;
	}


	&__text {
		display: flex;
		width: 100%;
		margin-top: auto;
		padding: remCalc( 30 );

		flex-direction: column;

		@include simpleTransition;
	}

	&__title {
		position: relative;
		max-width: 90%;
		margin: auto remCalc( 0 ) 0;
		padding-bottom: 0;
		
		font-size: remCalc( 40 );
		font-weight: $fontExLight;
		line-height: 1;

		transform-origin: left center;

		@include simpleTransition;

		@media( min-width: $tabletWidthMin ) {
			width: 100%;
			font-size: remCalc( 60 );
		}		

		&:after {
			content: '';

			position: absolute;
			bottom: 0;

			display: block;
			width: 0;
			height: 0;

			background-color: $purpleLig;

			opacity: 0;
			visibility: hidden;

			@include simpleTransition;
		}

		#{$block}:hover & { 
			margin-bottom: remCalc( 16 );
			padding-bottom: remCalc( 16 );

			&:after {
				opacity: 1;
				visibility: visible;
				width: remCalc( 70 );
				height: remCalc( 2 );
			}
		}
	}


	&__extra {
		position: absolute;
		bottom: remCalc( 30 );

		opacity: 0;
		visibility: hidden;

		transform: scale( 0.95 ) translate3d( 0, remCalc( 50 ), 0 );
		transform-origin: 0% 50%;
	
		@include simpleTransition;

		#{$block}:hover & {
			opacity: 1;
			visibility: visible;
			transform: scale( 1 ) translate3d( 0, 0, 0 );
		}
	}


	&__content {
		padding-left: remCalc( 5 );
		padding-right: remCalc( 5 );
	}


	&__img {
		position: absolute;
		top: 0;
		left: 0;
		
		width: 100%;
		height: 100%;
		
		object-fit: cover;
		
		@include simpleTransition;
		will-change: transform;

		z-index: -100;

		#{$block}:hover & {
			filter: grayscale( .5 );
			transform: scale( 1.25 );
		}
	}

	&__attr {

		&__label {
			margin-right: remCalc( 3 );
			font-weight: $fontLight;
			font-size: remCalc( 16 );
			letter-spacing: 0.1em;
		}

		&__value {
			font-weight: $fontBold;
			font-size: remCalc( 18 );
			letter-spacing: 0.025em;
		}

		&__link {
			font-weight: $fontBold;
			font-size: remCalc( 18 );
			letter-spacing: 0.025em;
			color: $purpleLig;
			text-decoration: none;

			@include simpleTransition;

			&:hover {
				color: $purpleViv;
			}
		}

		&__stars {
			color: $grayText;
		}
	}

	&__rate {
		font-size: remCalc( 40 );

		.icon--star {
			cursor: pointer;
		}
	}

	&__remove {
		position: absolute;
		top: remCalc( 20 );
		right: remCalc( 20 );
		
		opacity: 0;
		visibility: hidden;

		display: block;
		width: 40px;
		height: 40px;

		border-radius: 50%;
		box-shadow: 0 0 remCalc( 25 ) remCalc( 10 ) #80668840;

		#{$block}:hover & {
			opacity: 1;
			visibility: visible;
			box-shadow: 0 0 remCalc( 25 ) remCalc( 10 ) #87469b40;
		}

		&:before,
		&:after {
			content: '';

			position: absolute;
			top: 50%;
			left: 50%;

			display: block;
			width: remCalc( 15 );
			height: remCalc( 2 );

			border-radius: remCalc( 3 );
			background-color: white;
			
			@include simpleTransition;
		}

		&:before {
			transform: translate( -50%, -50% ) rotateZ( 45deg );
		}

		&:after {
			transform: translate( -50%, -50% ) rotateZ( -45deg );
		}

		&:hover:before,
		&:hover:after {
			width: remCalc( 18 );
			height: remCalc( 3 );
		}

		@include purpleHover;
	}

	&__btns {
		display: flex;
		position: absolute;
		top: remCalc( 30 );
		right: remCalc( 30 );
		
		opacity: 0;
		visibility: hidden;

		@include simpleTransition;

		#{$block}:hover & {
			opacity: 1;
			visibility: visible;
		}
	}

	&__btn {
		display: flex;
		align-items: center;
		justify-content: center;
		width: remCalc( 45 );
		height: remCalc( 45 );
		margin-left: remCalc( 18 );
		border-radius: 50%;

		@include purpleHover;

		&__icon {
			display: block;
			width: remCalc( 18 );
			height: remCalc( 18 );

			& svg {
				fill: white;
			}
		}		
	}

}