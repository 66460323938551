.icon {

	&--star {
		color: $grayText;
		@include simpleTransition;

		&--filled {
			color: $purpleLig;
		}
	}

}