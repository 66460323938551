*,
::before,
::after { box-sizing: border-box; }

html,
body {
    min-height: 100vh;
    text-rendering: optimizeLegibility;
    text-rendering: geometricPrecision;
    font-smooth: always;
    font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    -webkit-font-smoothing: subpixel-antialiased;    
}

body {
    padding-bottom: remCalc( 40 );

    font-family: 'Source Sans Pro', sans-serif;
	font-weight: $fontReg;
    font-size: remCalc( 16 );
    line-height: 1.4;
    
	color: $white;
	background-color: $grayMed;
}

#root {
    min-height: 100vh;
}

i {
    font-style: normal;
}