$grayDark:	#0d0d0d;
$grayMed: 	#141414;

$grayText:	#808080;

$white: 	#fff;

$purpleLig: #806688;
$purpleViv: #87469b;
// $purpleViv:	#8b15be;

$fontExLight: 	200;
$fontLight: 	300;
$fontReg: 		400;
$fontBold: 		700;

$blockWidth: 		remCalc( 420 );

$phoneWidthMax: 	remCalc( 600 );
$tabletWidthMin: 	remCalc( 601 );
$tabletWidthMax: 	remCalc( 768 );
$desktopWidthMin: 	remCalc( 1200 );

$sidePaddingMobile: 	remCalc( 25 );
$sidePaddingTablet:		remCalc( 40 );
$sidePaddingDesktop: 	remCalc( 69 );