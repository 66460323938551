.nav {
	position: sticky;
	top: 0;

	display: flex;
	align-content: center;
	height: remCalc( 85 );
	margin-bottom: remCalc( 40 );
	
	background-color: rgba( $grayDark, .95 );
	z-index: 1000;

	@include pageSidePadding;

	&__btn {
		margin-right: remCalc( 40 );
		padding: 0;

		font-size: remCalc( 20 );
		text-shadow: 0 0 0 rgba( $grayText, .5 );
		color: $grayText;

		transition: all .5s;

		&--active,
		&:hover {
			color: $white;
			text-shadow: 0 0 remCalc( 10 ) rgba( $white, .5 );

		}
	}


	&__adder {
		position: relative;
		display: block;
		margin-left: auto;
		align-self: center;

		width: remCalc( 35 );
		height: remCalc( 35 );
		border-radius: 50%;

		@include purpleHover;

		@media( min-width: $tabletWidthMin ) {
			width: remCalc( 50 );
			height: remCalc( 50 );
		}			

		&:before,
		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			display: block;
			background-color: white;
			transform: translate( -50%, -50% );
			border-radius: remCalc( 3 );
		}
	
		&:before {
			width: remCalc( 1.5 );
			height: remCalc( 15 );

			@media( min-width: $tabletWidthMin ) {
				width: remCalc( 2.5 );
				height: remCalc( 20 );
			}				
		}
		
		&:after {
			width: remCalc( 15 );
			height: remCalc( 1.5 );

			@media( min-width: $tabletWidthMin ) {
				width: remCalc( 20 );
				height: remCalc( 2.5 );
			}				
		}			
	}

}